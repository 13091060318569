import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { HomePreviewDataModel } from '@/types/home';
import { useWindowSize } from 'react-use';
import styles from './index.module.less'
import Image from 'next/image';
import { commonImgPath } from '@/shared/app-common';
import { ChevronUp, ChevronDown } from 'lucide-react'
import classNames from 'classnames';
import { useRouter } from 'next/router';

export const featureListData = [
  {
    imgPath: commonImgPath + '/e4e3f8d2-7b5f-4c3d-9f6a-1d8e9f0a2b3c.webp',
    title: 'View Instagram Anonymously',
    subTitle: 'View Stories, search profiles, and explore comments without leaving a trace.',
    icon: '/images/home/TrackingTool/glasses-icon.webp',
    link: '/view-instagram-anonymously',
    list: [
      {
        imgPath: '/images/home/TrackingTool/glasses1-icon.webp',
        title: 'View Instagram Stories Anonymously',
        link: '/view-instagram-anonymously/stories',
      },
      {
        imgPath: '/images/home/TrackingTool/search-icon.webp',
        title: 'Search Users Anonymously',
        // link: '/view-instagram-anonymously/users-search',
      },
      {
        imgPath: '/images/home/TrackingTool/chat-icon.webp',
        title: 'View Instagram Comments Privately',
        // link: '/view-instagram-anonymously/comments',
      },
    ]
  },
  {
    imgPath: commonImgPath + '/a1b2c3d4-e5f6-4a7b-8c9d-0e1f2a3b4c5d.webp',
    title: 'Track Instagram Anonymously',
    subTitle: 'Monitor likes, follows, and interactions discreetly.',
    icon: '/images/home/TrackingTool/track-icon.webp',
    link: '/instagram-tracker',
    list: [
      {
        imgPath: '/images/home/TrackingTool/follower-icon.webp',
        title: 'Instagram Follower Tracker',
        link: '/instagram-tracker/follower-tracker',
      },
      {
        imgPath: '/images/home/TrackingTool/instagram1-icon.webp',
        title: 'Instagram Activity Tracker',
        // link: '/instagram-tracker/activity-tracker',
      },
      {
        imgPath: '/images/home/TrackingTool/like-icon.webp',
        title: 'See Likes on Instagram',
        // link: '/instagram-tracker/see-someones-likes',
      },
      {
        imgPath: '/images/home/TrackingTool/hashtags-icon.webp',
        title: 'Hashtags Generator',
        // link: '/instagram-tracker/best-hashtags',
      },
    ]
  },
  {
    imgPath: commonImgPath + '/9f8e7d6c-5b4a-3c2d-1e0f-9a8b7c6d5e4f.webp',
    title: 'Download Instagram Stories',
    subTitle: 'Save Stories and Highlights anonymously',
    icon: '/images/home/TrackingTool/download-icon.webp',
    link: '/download-from-instagram',
    list: [
      {
        imgPath: '/images/home/TrackingTool/save-icon.png',
        title: 'Save Stories Anonymously',
        link: '/download-from-instagram/story',
      },
      {
        imgPath: '/images/home/TrackingTool/download1-icon.png',
        title: 'Download Highlights',
        // link: '/download-from-instagram/highlights',
      },
    ]
  },
  {
    imgPath: commonImgPath + '/3b2a1c4d-5e6f-7a8b-9c0d-1e2f3a4b5c6d.webp',
    title: 'Discover Instagram Influencer Trends',
    subTitle: 'Track what influencers are doing and uncover new gossip.',
    icon: '/images/home/TrackingTool/trends-icon.webp',
    link: '/instagram-influencer-trends',
    list: [
      {
        imgPath: '/images/home/TrackingTool/track-influencer-activity-icon.png',
        title: 'Track Influencer Activity',
        // link: '/instagram-influencer-trends/track-activity',
      },
      {
        imgPath: '/images/home/TrackingTool/uncover-gossip-icon.png',
        title: 'Uncover Gossip',
        // link: '/instagram-influencer-trends/uncover-gossip',
      },
    ]
  },
  {
    imgPath: commonImgPath + '/7d6e5f4a-3b2c-1d0e-9f8a-7b6c5d4e3f2a.webp',
    title: 'Monitor Suspicious Instagram Activity',
    subTitle: 'Spot red flags like sudden follower spikes or unusual behavior.',
    icon: '/images/home/TrackingTool/monitor-icon.webp',
    link: '/catch-instagram-cheaters',
    list: [
      {
        imgPath: '/images/home/TrackingTool/track-cheating-activity-icon.png',
        title: 'Track Cheating Activity',
        // link: '/catch-instagram-cheaters/track-cheating-activity',
      },
    ]
  },
]

const InstagramTrackingToolContent = ({ preViewData }: { preViewData?: HomePreviewDataModel }) => {
  const { t } = useTranslation('common');
  const { width } = useWindowSize()
  const [selectItem, setSelectItem] = useState(featureListData[0].title)
  const router = useRouter()

  const getItem = (item: {
    imgPath?: string;
    title?: string;
    subTitle?: string;
    icon?: string;
    link?: string;
    list?: {
      imgPath?: string;
      title?: string;
      link?: string;
    }[];
  }, index: number) => {
    return <div
      key={item?.title}
      className={classNames([styles.itemContent, (width < 768 && selectItem !== item?.title) && styles.phoneItemContentUnSelect])}
      style={{ backgroundImage: `url(${item?.imgPath})` }}
    >
      <div className={styles.topContent}>
        <div className={styles.titleContent} style={{ 'cursor': (item?.link?.length > 0) ? 'pointer' : 'auto' }}>
          <Image src={item?.icon} alt='' width={24} height={24} />
          <h3 onClick={() => { item?.link && router.push(item?.link) }}>
            {t(item?.title)}
          </h3>
        </div>
        <span
          className={styles.arrowContainer}
          style={{ color: (selectItem === item?.title) ? "#725CFF" : "#0C0C0C" }}
          onClick={() => {
            (width < 768) && setSelectItem(item?.title)
          }}
        >
          {(selectItem === item?.title) ? <ChevronUp /> : <ChevronDown />}
        </span>
      </div>

      {(width > 768 || selectItem === item?.title) && <div className={styles.otherContent}>
        <p className={styles.desc}>{t(item?.subTitle)}</p>

        <div className={styles.subListContent}>
          {item?.list?.map((subItem, subIndex) => {
            return <div
              key={subItem?.title}
              className={styles.subItem}
              style={{ 'cursor': (subItem?.link?.length > 0) ? 'pointer' : 'auto' }}
              onClick={() => { subItem?.link && router.push(subItem?.link) }}
            >
              <Image src={subItem?.imgPath} alt='' width={24} height={24} />
              <span>{t(subItem?.title)}</span>
            </div>
          })}
        </div>
      </div>}

    </div>
  }

  return <div className={styles.content}>
    <h2>{t("The #1 Instagram Tracking Tool")}</h2>

    <div className={styles.listContent}>
      {featureListData?.slice(0, 3).map((item, index) => {
        return getItem(item, index)
      })}
    </div>

    <div className={classNames([styles.listContent, styles.bottomListContent])}>
      {featureListData?.slice(3).map((item, index) => {
        return getItem(item, index)
      })}
    </div>

  </div>
};

export default InstagramTrackingToolContent;
